<template>
  <file-page
    v-bind:allowDownload="allowDownload"
    fileType="CONTRATO_FORNECEDOR"
    pageTitle="Contratos - Energia"
    v-bind:columnList="columnList"
    v-bind:selectVal="selectVal"
    v-bind:filterList="filterList"
  ></file-page>
</template>

<script>
import FilePage from '../../components/filePage/filePage.vue';
import VueCookies from 'vue-cookies';
import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import Vue from 'vue';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';

const CONFIG = new Config();

Vue.use(VueCookies);
Vue.use(VueToast);

export default {
  name: 'contratoForecedorPage',
  components: {
    FilePage
  },
  data() {
    return {
      allowDownload: true,
      columnList: [
        {
          key: 'nomeUnidade',
          label: 'UNIDADE',
          enableFilter: true,
          sortingOrder: 'asc'
        },
        {
          key: 'fornecedor',
          label: 'FORNECEDOR',
          enableFilter: true
        },
        {
          key: 'operacao',
          label: 'OPERAÇÃO',
          enableFilter: true
        },
        {
          key: 'prazo',
          label: 'PRAZO',
          enableFilter: true
        },
        {
          key: 'vigenciaIni',
          label: 'VIGÊNCIA INI.',
          enableFilter: false,
          sortingOrder: 'desc'
        },
        {
          key: 'vigenciaFim',
          label: 'VIGÊNCIA FIM',
          enableFilter: false,
          sortingOrder: 'desc'
        },
        {
          key: 'nomeArquivo',
          label: 'NOME DO ARQUIVO',
          enableFilter: false,
          sortingOrder: 'asc'
        }
      ],
      config: CONFIG,
      selectVal: {},
      filterList: [
        {
          key: 'anoVigencia',
          label: 'ANO VIGÊNCIA',
          columnStart: 'vigenciaIni',
          columnEnd: 'vigenciaFim',
          columnsFormat: 'YYYY-MM-DD'
        }
      ]
    };
  }
};
</script>
